<template>
    <div class="step-content">
        <i class="iconfont">&#xe656;</i>
        <div class="text">恭喜您！完成推广！</div>
        <div class="operate-btn">
            <el-button class="btn-red-line" @click="goBack">返回编辑单元</el-button>
            <el-button class="btn-red" @click="toNext">查看推广详情</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            goBack() {
                this.$router.push({
                    path: '/student/infographicPromotion/stepThree',
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                        editId: this.$route.query.editId
                    }
                })
            },
            toNext() {
                this.$router.push({
                    path: '/student/infographicPromotion/list',
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                    }
                })
                // localStorage.removeItem('infoStepTwo')
            }
        }
    }
</script>

<style scoped lang="scss">
    .step-content {
        background: #fff;
        height: calc(100vh - 186px);
        text-align: center;
    }
    .iconfont {
        color: #20D08C;
        font-size: 60px;
        margin-top: 100px;
        display: inline-block;
    }
    .text {
        font-size: 24px;
        line-height: 1;
        margin-top: 30px;
    }
    .operate-btn {
        margin-top: 40px;
    }
</style>